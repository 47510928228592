import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'
import styles from './installations.module.css'

import berlinProjectThumb from '../../../assets/images/albums/installations/berlin-project-thumbnail.jpg'
import earlyProjectsThumb from '../../../assets/images/albums/installations/installations-thumbnail.jpg'
import meadowsThumb from '../../../assets/images/albums/installations/meadows-thumbnail.jpg'
import curatorialProjectThumb from '../../../assets/images/albums/installations/curatorial-project-thumbnail.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <article className={styles.wrapper}>
        <div className={styles.galleryItem}>
          <Link to="/albums/installations/early-projects">
            <img
              src={earlyProjectsThumb}
              alt="A thumbnail for the early installation projects gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Early Projects</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/installations/berlin-project">
            <img
              src={berlinProjectThumb}
              alt="A thumbnail for the Berlin Project gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Berlin Project</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/installations/environmental-justice">
            <img
              src={meadowsThumb}
              alt="A thumbnail for the Meadows - The Gardening Revolution project"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Environmental Justice Projects</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/installations/curatorial-project">
            <img
              src={curatorialProjectThumb}
              alt="A thumbnail for the curatorial project - State of the Earth"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Curatorial Project</h2>
        </div>
      </article>
    </main>
    <Footer />
  </Layout>
)
